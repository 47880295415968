/*
  Header JS
*/
$(function () {
	// GNB Cookie Layer
	OLEDUI.GNBCookieLayer = function () {
		const cookieLayer = $(".cookie-area");
		if (cookieLayer.length == 0) return false;
		const contEu = cookieLayer.find(".eu");
		const contNonEu = cookieLayer.find(".non-eu");
		if (!!COUNTRY_CODE) {
			if (COUNTRY_CODE == "EU") {
				contNonEu.remove();
			} else {
				contEu.remove();
			}
			cookieLayer.addClass("active");
		}
		const btnNo = cookieLayer.find(".btn.no-thanks");
		const btnOk = cookieLayer.find(".btn.accept-all");
		const btnClose = cookieLayer.find(".btn-close");
		const closeLayer = function () {
			cookieLayer
				.slideUp("slow")
				.promise()
				.done(function () {
					cookieLayer.removeClass("active");
				});
		};
		btnNo.on("click", function (e) {
			e.preventDefault();
			$.cookie("COOKIE_AGREE", "N", { path: "/" + OLEDUI.SITE });
			closeLayer();
		});
		btnOk.on("click", function (e) {
			e.preventDefault();
			closeLayer();
			$.cookie("COOKIE_AGREE", "Y", { path: "/" + OLEDUI.SITE });
			location.reload();
		});
		btnClose.on("click", function (e) {
			e.preventDefault();
			closeLayer();
			if (COUNTRY_CODE !== "EU") {
				$.cookie("COOKIE_AGREE", "Y", { path: "/" + OLEDUI.SITE });
				location.reload();
			}
		});
	};
	const cookieYN = $.cookie("COOKIE_AGREE");
	if (!cookieYN && $(".LEGA").length === 0 && !cookieYN && $(".E404").length === 0) OLEDUI.GNBCookieLayer();

	// Skip to content
	OLEDUI.SkipToContent = function () {
		const $skipLink = $(".skip-to-content a");
		OLEDUI.layout.main.attr("tabindex", -1);
		$skipLink.on("click", function () {
			event.preventDefault();
			OLEDUI.layout.main.focus();
		});
	};
	OLEDUI.SkipToContent();

	// GNB focus control
	OLEDUI.GNBControlFocus = function () {
		const searchLayer = OLEDUI.layout.header.find(".search-layer");
		const gnbLayer = OLEDUI.layout.header.find(".gnb-layer");
		const skipLink = $(".skip-to-content a");
		const cookieLayer = $(".cookie-area");
		const logo = OLEDUI.layout.header.find(".gnb-area .logo a");
		const logoMobile = OLEDUI.layout.header.find(".gnb-layer .logo a");
		// When navigating the links in the search layer and menu layer with the tab key,
		// the logo is focused in the markup order,
		// and if the layer is open, it returns focus into the layer again.
		logo.on("focus", function () {
			if (searchLayer.hasClass("active")) {
				searchCloseBtn.focus();
			} else if (gnbLayer.hasClass("active")) {
				gnbLayer.find(".close").focus();
			}
		});
		logo.on("click", function () {
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: "oledspace-logo",
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		logoMobile.on("click", function () {
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: "oledspace-logo",
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		// Because you can jump to main by clicking the skip to content link,
		// When this link is focused, it returns the focus back to the cookie layer
		// so that it cannot go to main if the cookie layer is active.
		// The skip to content link is only available when the cookie layer is closed.
		skipLink.on("focus", function () {
			if (cookieLayer.hasClass("active")) {
				cookieLayer.find("a").focus();
			}
		});
	};
	OLEDUI.GNBControlFocus();

	// GNB Scroll
	OLEDUI.layout.header.each(function () {
		const hasWhite = $(this).hasClass("white");
		$(this).data("hasWhite", hasWhite);
	});
	OLEDUI.GNBScroll = function () {
		const sc = $(window).scrollTop();
		if (sc <= 1 && OLEDUI.layout.header.hasClass("scrolled")) {
			OLEDUI.layout.header.removeClass("scrolled");
		}
		if (sc > 1 && !OLEDUI.layout.header.hasClass("scrolled")) {
			OLEDUI.layout.header.addClass("scrolled");
		}
	};
	$(window)
		.on("scroll", function () {
			OLEDUI.GNBScroll();
		})
		.scroll();

	// gnb layer
	OLEDUI.GNBMenu = function () {
		const wrapper = $(".wrapper");
		const gnbBtn = OLEDUI.layout.header.find(".btn-menu");
		const gnbArea = OLEDUI.layout.header.find(".gnb-area");
		const gnbLayer = OLEDUI.layout.header.find(".gnb-layer");
		const gnbStoreBtn = OLEDUI.layout.header.find(".btn-store");
		const gnbDimmed = OLEDUI.layout.header.find(".dimmed");
		const gnbDepth1 = gnbArea.find(".nav-pc .depth1 .has-sub > span > a");
		const gnbDepth1Link = gnbArea.find(".nav-pc .depth1 li:not(.has-sub) > span > a");
		const gnbDepth2Link = gnbArea.find(".nav-pc .depth2 a");
		const langBtn = gnbArea.find(".utils-area .has-sub .link-lang");
		const gnbOutLink = gnbArea.find(".outlink a");
		const cookieLayer = $(".cookie-area");
		const gnbActive = function () {
			event.preventDefault();
			if (gnbLayer.hasClass("is-active")) {
				gnbLayer.removeClass("is-active");
				gnbDimmed.removeClass("is-opened");
				gnbArea.removeClass("is-closed");
				cookieLayer.removeClass("is-closed");
				setTimeout(function () {
					gnbLayer.hide();
					gnbBtn.focus();
				}, 500);
			} else {
				gnbLayer.show();
				setTimeout(function () {
					gnbLayer.addClass("is-active");
					gnbDimmed.addClass("is-opened");
					gnbArea.addClass("is-closed");
					cookieLayer.addClass("is-closed");
					setTimeout(function () {
						gnbLayer.find("a, input, button").first().focus();
					}, 500);
				}, 100);
			}
			// 22.08.22 제거
			// setTimeout(function () {
			// 	wrapper.toggleClass("layer");
			// }, 900);
			$("html").toggleClass("is-layer-open");
			if ($("html").hasClass("is-layer-open")) {
				OLEDUI.bandPageScroll();
			} else {
				OLEDUI.removeStyle();
			}
		};
		const gnbNavLangActive = function () {
			event.preventDefault();
			if (!gnbLayer.find(".language").hasClass("is-active")) {
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: "languages",
						navDepth2: "",
						clickURL: "",
					},
				});
			}
			gnbLayer.find(".language").toggleClass("is-active");
			var linkURL = $(event.target).attr("href");
			if (linkURL && linkURL.indexOf("#") == -1) {
				if (getCookie("test") != "!!") location.href = linkURL;
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: "languages",
						navDepth2: convertTitle($(event.target).text()),
						clickURL: $(event.target).attr("href"),
					},
				});
			}
		};
		const gnbNavActive = function () {
			event.preventDefault();
			if (!$(this).closest(".has-sub").hasClass("is-active")) {
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: convertTitle($(this).text()),
						navDepth2: "",
						clickURL: "",
					},
				});
			}
			$(this).closest(".has-sub").toggleClass("is-active");
		};
		const gnbClickPC = function () {
			event.preventDefault();
			const depth1 = $(this).closest(".nav-pc");
			const depth2 = $(this).closest(".has-sub");
			if (depth2.hasClass("clicked")) {
				gnbArea.find(".nav-pc .depth1 .has-sub").removeClass("clicked");
				depth1.removeClass("clicked");
			} else {
				gnbArea.find(".nav-pc .depth1 .has-sub").removeClass("clicked");
				depth1.addClass("clicked");
				depth2.addClass("clicked");
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: convertTitle($(this).text()),
						navDepth2: "",
						clickURL: "",
					},
				});
			}
		};
		const gnbLeavePC = function () {
			gnbArea.find(".nav-pc .depth1 .has-sub").removeClass("clicked");
			gnbArea.find(".nav-pc").removeClass("clicked");
			gnbArea.find(".utils-area .has-sub").removeClass("is-active");
			langBtn.removeClass("opened");
		};
		const gnbToggleLangPC = function () {
			event.preventDefault();
			$(this).addClass("opened").attr("aria-expanded", true).parent().toggleClass("is-active");
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: "languages",
					navDepth2: "",
					clickURL: "",
				},
			});
		};

		// desktop - 클릭시 열림 & 포커스 시 열림 & 함수 중복 실행 제거
		gnbDepth1Link.on("click", function () {
			// store link for desktop
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbDepth2Link.on("click", function () {
			// depth2 link for desktop
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).closest(".has-sub").find("> span > a").text()),
					navDepth2: convertTitle($(this).text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbOutLink.on("click", function () {
			// aliance, .language a for PC
			if ($(this).hasClass("link-alliance")) {
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: convertTitle($(this).text()),
						navDepth2: "",
						clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
					},
				});
			} else {
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: "languages",
						navDepth2: convertTitle($(this).text()),
						clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
					},
				});
			}
		});
		gnbDepth1.on("click", gnbClickPC);
		gnbDepth1.on("mouseenter", function () {
			$(this).addClass("hover");
		});
		gnbDepth1.on("mouseleave", function () {
			$(this).removeClass("hover");
		});
		gnbDepth1.on("focus", function () {
			if (!$(this).hasClass("hover")) {
				$(this).trigger("click");
			}
		});
		gnbArea.on("mouseleave", gnbLeavePC);
		gnbArea.find(".nav-pc button.close").on("click", gnbLeavePC);
		langBtn.on("click", gnbToggleLangPC);
		gnbArea.find(".outlink > li:not(.has-sub)").find("button, a").on("mouseenter", gnbLeavePC);

		// mobile
		gnbBtn.on("click", gnbActive);
		gnbLayer.find(".has-sub > span > a").on("click", gnbNavActive);
		gnbLayer.find(".depth1 li:not(.has-sub) > span > a").on("click", function () {
			// oled store for mobile
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbLayer.find(".depth2 li a").on("click", function () {
			// depth2 link for mobile
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).closest(".has-sub").find("> span > a").text()),
					navDepth2: convertTitle($(this).text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbLayer.find(".outlink .alliance a").on("click", function () {
			// alliance for mobile
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbLayer.find(".gnb-inner-wrap .close").on("click", gnbActive);
		gnbLayer.find(".language").on("click", gnbNavLangActive);
		gnbLayer.find(".social a").on("click", function () {
			pushDataLayer({
				event: "click_social_media",
				click_social_media: {
					socialMediaName: convertTitle($(this).parent().attr("class")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
        gnbStoreBtn.on("click", function () {
			// store link for mobile
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "header",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		gnbDimmed.on("click", gnbActive);
	};
	OLEDUI.GNBMenu();

	OLEDUI.GNBSearch = function () {
		const searchBtn = OLEDUI.layout.header.find(".btn-search");
		const searchCloseBtn = OLEDUI.layout.header.find(".btn-layer-close");
		const wrapper = $(".wrapper");
		const searchLayer = OLEDUI.layout.header.find(".search-layer");
		const searchForm = searchLayer.find("form");
		const searchInput = searchLayer.find("#searchInput");
		const searchDelete = searchLayer.find(".delete-btn");
		const searchTitle = searchLayer.find(".search-title");
		const ajaxURL = searchForm.attr("action");
		const searchResultY = searchLayer.find(".search-result");
		const searchResultN = searchLayer.find(".no-search");
		const searchKeywords = searchLayer.find(".keyword-list li button");
		const footerFloating = OLEDUI.layout.footer.find(".floating");
		const oledArea = $("#aboutOledList");
		const INSDArea = $("#searchFestList");
		const TECHArea = $("#searchTechList");
		const storeArea = $("#oledStoreList");
		const alliArea = $("#allianceList");
		const initSerachLayer = function () {
			searchInput.val("");
			searchTitle.removeAttr("style");
			searchDelete.removeClass("active");
			searchResultY.removeClass("active");
			searchResultN.removeClass("active");
		};
		const searchLayerActive = function () {
			if (!searchLayer.hasClass("active")) {
				// open
				searchLayer.show();
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "header",
						navDepth1: "search",
						navDepth2: "",
						clickURL: "",
					},
				});
				setTimeout(function () {
					searchLayer.addClass("active");
					initSerachLayer();
					searchCloseBtn.focus();
					//OLEDUI.smoothscroll.destroy();
					setTimeout(function () {
						footerFloating.addClass("top-show");
					}, 900);
				}, 100);
			} else {
				// close
				searchLayer.removeClass("active");
				//OLEDUI.smoothscroll.init();
				searchBtn.focus();
				setTimeout(function () {
					searchLayer.hide();
				}, 900);
			}
			// 22.08.22 제거
			// setTimeout(function () {
			// 	wrapper.toggleClass("layer");
			// }, 900);
			$("html").toggleClass("is-layer-open");
			if ($("html").hasClass("is-layer-open")) {
				OLEDUI.bandPageScroll();
			} else {
				OLEDUI.removeStyle();
			}
		};
		searchBtn.on("click", searchLayerActive);
		searchCloseBtn.on("click", searchLayerActive);

		const activeDelButton = function () {
			const searchTxt = searchInput.val();
			if (searchTxt.length == 0) {
				searchDelete.removeClass("active");
			} else {
				searchDelete.addClass("active");
			}
		};
		const searchAjax = function () {
			const searchTxt = searchInput.val();

			if (searchTxt.length < 2) {
				hideResults(searchResultY, searchResultN);
			} else {
				const request = $.ajax({ url: ajaxURL, data: searchForm.serialize(), dataType: "JSON" });

				request
					.done(function (data, textStatus) {
						const results = data.result;

						if (results.length === 0) {
							hideResults(searchResultY, searchResultN);
						} else {
							const categoryLists = categorizeResults(results);

							displayResults(categoryLists);
						}
					})
					.fail(function (textStatus) {
						console.log("fail", textStatus.responseText);
					})
					.always(function () { });
			}
		};

		const hideResults = function (positiveResultArea, negativeResultArea) {
			positiveResultArea.removeClass("active");
			negativeResultArea.addClass("active");
		};

		const categorizeResults = function (results) {
			let OLEDList = [];
			let INSDList = [];
			let TECHList = [];
			let STORList = [];
			let ALLIList = [];

			for (let i = 0; i < results.length; i++) {
				const key = results[i].key;

				if (key.includes("OLEDb") || key.includes("OLEDc")) {
					OLEDList.push(results[i]);
				} else if (key.includes("INSD")) {
					INSDList.push(results[i]);
				} else if (key.includes("PROD")) {
					TECHList.push(results[i]);
				} else if (key.includes("STOR")) {
					STORList.push(results[i]);
				} else if (key.includes("ALLI")) {
					ALLIList.push(results[i]);
				}
			}

			return { OLEDList, INSDList, TECHList, STORList, ALLIList };
		};

		const displayResults = function (categoryLists) {
			const { OLEDList, INSDList, TECHList, STORList, ALLIList } = categoryLists;

			displayCategoryResults(oledArea, OLEDList);
			displayCategoryResults(INSDArea, INSDList, true);
			displayCategoryResults(TECHArea, TECHList);
			displayCategoryResults(storeArea, STORList);
			displayCategoryResults(alliArea, ALLIList);

			OLEDUI.Lazyload();

			showResults(searchResultY, searchResultN);
		};

		const displayCategoryResults = function (area, list, includeDate = false) {
			if (list.length > 0) {
				let HTML = "";

				for (let i = 0; i < list.length; i++) {
					HTML += '<li class="list-item"><a href="' + list[i].url + '">';
					HTML += '<div class="img">';
					HTML += '<img src="/' + list[i].thumbnailImg + '" alt="' + list[i].thumbnailText + '" aria-hidden="true" onerror="this.src=\'//via.placeholder.com/670x503/f0f0f0/3333330\'" />';
					HTML += '</div> <div class="text">';

					if (includeDate) {
						HTML += '<div class="date">' + list[i].date + '</div>';
					}

					HTML += '<div class="title">' + list[i].title + "</div>";
					HTML += list[i].breadcrumbText;
					HTML += "</div></a></li>";
				}

				area.find(".count").text(list.length);
				area.find("ul").html(HTML);
				area.show();
			} else {
				area.hide();
			}
		};

		const showResults = function (positiveResultArea, negativeResultArea) {
			positiveResultArea.addClass("active");
			negativeResultArea.removeClass("active");
		};

		// search ajax
		searchDelete.on("click", function () {
			searchInput.val("").focus();
			searchDelete.removeClass("active");
			searchResultY.removeClass("active");
			searchResultN.addClass("active");
		});
		searchForm.on("submit", function () {
			pushDataLayer({
				event: "search",
				search: {
					recommendKeyword: "False",
					searchKeyword: convertTitle(searchInput.val()),
				},
			});
			searchAjax();
			return false;
		});
		searchInput
			.on("focus", function () {
				searchTitle.slideUp();
			})
			.on("keyup", function () {
				event.preventDefault();
				activeDelButton();
				if (event.keyCode === 13) {
					// enter
					searchAjax();
				}
			});

		searchKeywords.on("click", function () {
			event.preventDefault();
			let text = $(this).text();
			// 240104 gt.yang : 검색창 키워드는 META Technology 2.0 인데, 저장된 title은 META Technology 이라서 분기 처리 하려했으나, 해당 text 검색결과도 나와서 보류
			// if (text.includes('META Technology') === true) {
			// 	text = 'META Technology';
			// }
			searchInput.val(text);
			// searchInput.focus();
			pushDataLayer({
				event: "search",
				search: {
					recommendKeyword: "True",
					searchKeyword: convertTitle(text),
				},
			});
			searchAjax();
			searchDelete.addClass("active");
		});

		searchLayer.on("click", ".no-search .recommended-list .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest(".recommended-list").find("> .tit").text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		searchLayer.on("click", ".search-result #searchTechList .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest("ul").attr("aria-label")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		searchLayer.on("click", ".search-result #aboutOledList .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest("ul").attr("aria-label")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		searchLayer.on("click", ".search-result #searchTechList .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest("ul").attr("aria-label")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		searchLayer.on("click", ".search-result #oledStoreList .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest("ul").attr("aria-label")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		searchLayer.on("click", ".search-result #allianceList .list-item a", function () {
			pushDataLayer({
				event: "click_search_result",
				click_search_result: {
					contentTitle: convertTitle($(this).find(".text .title").text()),
					contentCategory: convertTitle($(this).closest("ul").attr("aria-label")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
	};
	OLEDUI.GNBSearch();
});
